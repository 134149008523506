import React from 'react'
import Logo from './assets/logo/Logo.png'

const Layout = ({ children }) => {
  return (
    <div className='container mx-auto min-h-screen flex justify-center items-center'>
      <div className='max-w-lg w-full sm:border-2 sm:border-teal-peg p-4 rounded-md flex items-center justify-center flex-col gap-2 sm:shadow-md '>
        <header
          className='flex
         flex-col justify-center items-center text-center text-lg px-2 mb-2'
        >
          <div className='max-w-xs'>
            <img  src={Logo} alt='DETROIT logo' />
          </div>
          <h1 className='font-bold text-4xl capitalize'>Cancel appointment</h1>
        </header>
        {children}
      </div>
    </div>
  )
}

export default Layout
