import cloudfunctions from "./cloudfunctions.json";
import axios from "axios";

const isProduction = process.env.REACT_APP_STAGE === "prod";

const endpoints = isProduction
  ? cloudfunctions.production
  : cloudfunctions.development;

const api = {
  cancelAppointment: async (payload) => {
    try {
      const res = await axios.post(endpoints.cancelAppointment, {
        ...payload,
      });
      return res;
    } catch (error) {
      console.error(error);
      throw new Error(error);
    }
  },
  getAppointmentData: async (payload) => {
    try {
      const res = await axios.post(endpoints.getAppointmentData, {
        ...payload,
      });

      return res;
    } catch (error) {
      console.error(error);
      throw new Error(error);
    }
  },
};

export default api;
